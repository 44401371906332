import React from "react"
import Footer from "./Footer";
import classnames from "classnames";
import { TopBar } from "./Topbar";
import { CookieBanner } from "./CookieBanner";
import { useCampaignStore } from "../hooks/Campaign";
import style from "./Layout.module.scss";
import "./_BaseStyle.scss";

type LayoutProps = {
  withBar: boolean;
  contentStyle?: string;
}

const Layout: React.FC<LayoutProps> = ({ withBar, contentStyle, children }) => {
  
  useCampaignStore();

  return (
    <div className={style.layout}>
        {withBar && <TopBar />}
        <main className={classnames(style.mainContent, contentStyle)}>
          {children}
        </main>
        <Footer />
        <CookieBanner />
    </div>
  );
}

export default Layout