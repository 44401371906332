import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import favicon from "../assets/icons/icon.ico";

type SEOProps = {
    title: string;
    description: string;
    structuredData?: string;
    image?: any;
    imageAlt?: string;
}

const SEO: React.FC<SEOProps> = ({ title, description, structuredData, image, imageAlt }) => {

    const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                siteUrl
              }
            }
          }
        `);

    const imagePath = image && image.src ? `${site.siteMetadata.siteUrl}${image.src}` : undefined;
    const imageWidth = image && image.width ? image.width : undefined;
    const imageHeight = image && image.height ? image.height : undefined;

    return (
        <Helmet htmlAttributes={{ lang: "en"} }>
            <title>{title}</title>
            <link rel="icon" href={favicon} />
            <meta name="description" content={description} />
            {structuredData && <script type="application/ld+json">{structuredData}</script>}
            <meta name="p:domain_verify" content="650ebc5c57888f4412b82c7e51fa73ee"/>
            <meta name="facebook-domain-verification" content="lb89pz497gbtemar4d7xak8s7r37pp"/>
            <meta name="twitter:site" content="@FirstDraftApp1" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:card" content={image ? "summary_large_image" : "summary"} />
            {imagePath && <meta name="og:image" content={imagePath} /> }
            {imageWidth && <meta name="og:image:width" content={imageWidth} /> }
            {imageWidth && <meta name="og:image:height" content={imageHeight} /> }
            {imagePath && imageAlt && <meta name="twitter:image:alt" content={imageAlt} /> }
        </Helmet>
    );
}

export default SEO;