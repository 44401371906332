import { useEffect } from "react";

export const useCampaignStore = () => {

    useEffect(() => {

        if(window !== undefined) {
            
            const urlCampaign = new URLSearchParams(window.location.search).get("cid");

            if(urlCampaign !== null) {
                window.localStorage.setItem("cid", urlCampaign);
            }
        }

    }, []);

}
