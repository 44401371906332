import React from "react";
import AppIcon from "../assets/svg/appicon.inline.svg";
import classnames from "classnames";
import { Link } from "gatsby";
import style from "./Topbar.module.scss";

type FixedBarProps = {
    className?: string;
}

export const TopBar: React.FC<FixedBarProps> = ({ className }) => {

    return (
        <header className={classnames(style.bar, className)} >
            <AppIcon />
            <nav className={style.nav}> 
                <Link className={style.largeLink} to="/">Prime Draft</Link>
                <div className={style.smallLinks}>
                    <Link to="/blog/" activeClassName={style.activeLink} partiallyActive={true}>Blog</Link>
                    <Link to="/faq/" activeClassName={style.activeLink} partiallyActive={true}>FAQ</Link>
                </div>
            </nav>
        </header>
    );
}