import React from "react";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import style from "./Footer.module.scss";

const Footer: React.FC = () => {

    return (
        <footer className={style.footer}>
            <div>
                <span>&#xa9; 2020 Blue Ink Software</span>
                <span className={style.divider}>|</span>
                <Link to="/privacystatement/">Privacy</Link>
                <span className={style.divider}>|</span>
                <a href="mailto:contact@primedraft.app">Contact</a>
            </div>
            <div> 
                { false && <OutboundLink href="https://twitter.com/FirstDraftApp1" target="_blank">Facebook</OutboundLink> }
                { false && <span className={style.divider}>|</span> }
                { false && <OutboundLink href="https://www.facebook.com/firstdraftwritingapp" target="_blank">Twitter</OutboundLink> }
            </div>
        </footer>
    );

}

export default Footer;