import { Link } from "gatsby";
import React, { useCallback, useState } from "react";
import CloseIcon from "../assets/svg/close.inline.svg";
import style from "./CookieBanner.module.scss";

const initialShowState = (): boolean => {

    // Gatsby Build Check
    if(typeof window === "undefined") return false;

    const cookieValue = window.document.cookie.split('; ').find(row => row.startsWith('cookie-banner-shown='));

    return cookieValue ? false : true;
}

const cookieMessageConfirmed = () => {

    document.cookie = "cookie-banner-shown=1; samesite=strict; max-age=31536000";

}

export const CookieBanner = () => {

    const [showBanner, setShowBanner] = useState(initialShowState);

    const onConfirm = useCallback(() => {
        cookieMessageConfirmed();
        setShowBanner(false);
    }, []);

    if(showBanner === false) return null;

    return(
        <div className={style.root}>
            <div>This website uses <Link to="/privacystatement/">cookies</Link> to help understand user interaction and improve site functionality.</div>               
            <CloseIcon className={style.close} onClick={onConfirm} />
        </div>
    );

}